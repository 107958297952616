<template>
  <div class="main-content">
    <div class="container">
    <div class="login">
      <div class="top_line"></div>
      <div class="register_main">
        <div class="title">现在就加入</div>
        <div class="title">登录</div>
        <div>
          <a-input placeholder="请输入邮箱" class="input" v-model="user.username"/>
        </div>
        <div>
          <a-input type="password" placeholder="请输入密码" class="input" v-model="user.password"/>
        </div>
        <div>
          <a-checkbox  v-model="isrember"  @change="remberonChange">
           记住我
          </a-checkbox>
        </div>
        <div>
          <a-button block type="danger" class="btn-red"  @click="doLogin">
            登录
          </a-button>
        </div>
        <div class="logins">
          <div>忘记密码？</div>
          <div><router-link to="/reg">没有账户</router-link></div>
        </div>
      </div>

    </div>
    </div>
  </div>
</template>

<script>
import Config from "../config";
import Storage from "@/common/storage";

export default {
  data() {
    return {
      server_url: Config.data.server_url,
      loading: false,
      user: {
        username: "",
        name: "",
        mobile: "",
        sex: "",
        email: "",
        photo: "",
        password: "",
        repassword: "",
      },
      isrember: false,
    }
  },
  created() {
    // 获取头部菜单内容
  },

  methods: {
    remberonChange(e) {
      console.log(`checked = ${e.target.checked}`);
      this.isrember = e.target.checked
    },
    async doLogin() {
      this.loading = true;
      if (this.user.username === "" ||  this.user.password === ""){
        this.$message.error('用户买或密码为空');
        return;
      }

      let result = await this.$post("/api/customer/login", {
          login_name: this.user.username,
          password: this.user.password,
      });
      if (result.status === true) {
        console.log("result", result.data);
        Storage.uid = result.data.uid;
        Storage.token = result.data.token;
        Storage.login_name = result.data.email;
        Storage.sex = result.data.sex;
        Storage.mobile = result.data.mobile;
        Storage.name = result.data.name;
        Storage.is_check_email = result.data.is_check_email;

        // Storage.myName = result.data.name;
        Storage.token_timeout = result.data.token_timeout;
        if (this.isrember===true) {
          Storage.account = result.data.login_name;
        } else {
          Storage.account = "";
        }
        this.$router.push({path: "/"});
      }


      this.loading = false;
    },

  }
}
</script>
<style lang="scss" scoped>
.main-content {
  display: flex;
justify-content: flex-end;

  background-image: url('../static/images/reg_bg1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 50vw;
  .container {
    background-image: url('../static/images/loginbg.png');
    background-repeat: repeat-y;
    height: 100vh;
    width: 1400px;
    display: flex;
    align-items: center;
    background-position: top center;
  }
}

.login {
  width: 400px;
  height: auto;
  margin-left: auto;
  margin-right: auto;


  .register_main {
    padding: 40px;
    background: #fff;
    border-radius: 0px 0px 8px 8px;
  }

  .top_line {
    height: 8px;
    background: #002654;
    border-radius: 8px 8px 0px 0px;
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    line-height: 45px;
    margin-bottom: 40px;
    text-align: center;
  }

  .input {
    background: #F7F9FA;
    border-radius: 24px 24px 24px 24px;
    height: 44px;
    opacity: 1;
    border: 1px solid #E6EAEB;
    margin-bottom: 24px;
  }

  .btn-red {
    height: 44px;
    margin-top: 24px;
    background-color: #CE1126;
    border-radius: 22px;
  }
}

.logins {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
}
</style>
